.modal-approve-book-info {
	.modal-dialog {
		max-width: 600px;
	}

	.modal-approve-book-info__book-thumbnail {
		width: 180px;
		margin-right: 1.5rem;
	}
}
