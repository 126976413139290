.tab-feed {
	.nav-item {
		.nav-link {
			padding: 10px;
			color: $primary;
			font-size: 1rem;
			font-weight: 300;
			border-radius: 4px;
			background-color: transparent;
			&.active {
				color: $primary;
				background-color: transparent;
				position: relative;
				font-weight: 600;
				transition: all 0.1s ease;

				&::after {
					content: '';
					position: absolute;
					top: 95%;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: #000;
					border-radius: 10px;
					transition: all 0.1s ease;
				}
			}
		}
	}
}

.user-image {
	width: 45px;
	height: 45px;
	border-radius: 5px;
	object-fit: cover;
}

.user-status {
	&--avt img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 50%;
	}

	&--name span {
		font-weight: 700;
	}
	&--createat {
		font-size: 0.8rem;
	}
}

.post-description {
	.tagged {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0.4rem;

		li {
			margin: 0 5px;
			width: fit-content;
			gap: 5px;
			padding: 12px 15px;
			line-height: 18px;
			border-radius: 10px;
			font-size: 0.75rem;
			background: $primary;
			color: #fff;
			font-weight: 300;
		}
	}
}

.post-book {
	.book-img {
		img {
			width: 140px;
			height: 200px;
			border-radius: 5px;
		}
	}

	.book-body {
		.book-name {
			font-weight: 800;
		}
		.book-author {
			font-size: 0.7rem;
		}
		.book-percent {
			.progress {
				border-radius: 99px;
				height: 10px;
				margin-bottom: 0;
			}
		}
	}
}

.action-bar {
	border-top: #f1f1f5 1px solid;
	border-bottom: #f1f1f5 1px solid;

	&--icon {
		cursor: not-allowed;
	}
}

.group-info {
	width: 30%;
	position: relative;
	&--img img {
		border-radius: 12px 12px 0 0;
		height: 140px;
		object-fit: cover;
		width: 100%;
		background-color: #fff;
	}

	&--name {
		position: absolute;
		background-color: $primary;
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		h6 {
			margin-bottom: 0;
			color: #fff;
			font-weight: 400;
			padding: 0 10px;
		}
	}
}
// .feed {
.card-body {
	.nav-tabs {
		margin: 0;
		border-bottom: 1px solid #eaedf2;
		.nav-item {
			font-size: 20px;
			margin-bottom: -2 !important;
		}
		.nav-item.active {
			margin-bottom: -1px;
			background: rgb(255, 255, 255);
			color: rgb(19, 19, 19);
			border-top: 1px solid #eaedf2;
			border-left: 1px solid #eaedf2;
			border-right: 1px solid #eaedf2;
		}
	}
}
.modalSearch {
	width: 100%;
	background-color: rgb(255, 255, 255);
	position: absolute;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	z-index: 100;

	.item-search {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 8px 10px;
		transition: 0.2s;
		border-bottom: 1px solid #f5f8fb;
		overflow: hidden;
		.img-search {
			width: 35px;
			height: 35px;
			border-radius: 50px;
			margin-right: 20px;
		}
		.name {
			margin: 0px;
		}
	}
	.item-search:hover {
		background-color: #f5f8fb;
	}
}

.no-result {
	text-align: center;
	padding-top: 10px;
}
// }
.render-content {
	p {
		margin: 0;
		font-size: 14px;
	}
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	color: #2d2c42;
	display: -webkit-box;
	margin-top: 1.25em;
	max-height: 4rem;
	overflow: hidden;
}
.box-feed-content {
	border: 1px solid rgb(240, 240, 240);
	padding: 20px 0px 5px 20px;
	border-radius: 10px;
	margin-bottom: 10px;
	.content-feed {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 7;
		color: #2d2c42;
		display: -webkit-box;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		max-height: 15rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.items-feed {
	display: flex;
	align-items: center;
	.title {
		width: 100px;
	}
	margin: 5px 0px;
}
.box-content {
	display: flex;
	gap: 30px;
	margin: 5px 0px;
	border: 1px solid rgb(240, 240, 240);
	padding: 10px 10px;
	border-radius: 5px;
	align-items: center;
}
.item-mentions {
	border: 1px solid rgb(240, 240, 240);
	background: rgb(255, 255, 255);
	padding: 5px;
	display: block;
	border-radius: 5px;
}

.main-confirm-my-book__confirm__images-uploaded {
	display: flex;
	gap: 20px;
	margin-top: 24px;
}

.main-confirm-my-book__confirm__image-item {
	width: 200px;
	min-width: 200px;
	height: 250px;
	position: relative;
	.create-post-modal-content__substitute__modify-image-item-delete {
		position: absolute;
		top: 5px;
		right: 5px;
		border: none;
		border-radius: 50%;
		padding: 0px 3px 2px 3px;
		svg {
			width: 20px;
			height: 20px;
		}
	}
	img {
		width: 100%;
		height: 100%;
	}
}

.main-modal-my-book__confirm__image-item {
	min-width: 200px;
	height: 250px;
	position: relative;
	.create-post-modal-content__substitute__modify-image-item-delete {
		position: absolute;
		top: 5px;
		right: 5px;
		border: none;
		border-radius: 50%;
		padding: 0px 3px 2px 3px;
		svg {
			width: 20px;
			height: 20px;
		}
	}
	img {
		width: 100%;
		height: 100%;
	}
}

.create-itembook__upload-image {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}
.main-confirm-my-book__confirm__image-over {
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.4);
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 36px;
	font-weight: 700;
	letter-spacing: 5px;
	display: none;
	user-select: none;
	&.show {
		display: flex;
	}
}

.create-post {
	.dropzone {
		border: 2px dashed rgb(145, 145, 145) !important;
		background: #ffffff;
		width: 200px;
		min-width: 200px;
		height: 250px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		cursor: pointer;
		.create-itembook__upload-image {
			min-height: 150px;
			background: #ffffff;
			width: 100%;
			height: 100%;
			display: flex;
			gap: 20px;
		}
	}

	.css-1s2u09g-control {
		padding-left: 4px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #66799e;
		border-radius: 0.5rem;
		border: 0.0625rem solid #d1d7e0;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&:hover {
			border-color: #d1d7e0;
		}
	}

	.css-1pahdxg-control {
		padding-left: 4px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		border-radius: 0.5rem;
		border-color: #566190 !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		@include box-shadow($input-box-shadow, $input-focus-box-shadow);
	}

	.css-6j8wv5-Input {
		color: #66799e;
		padding: 0.25rem 0;
	}

	.create-book__seri-input {
		box-shadow: none;
		&:read-only {
			background-color: white !important;
		}
		&:focus {
			border: 0.0625rem solid #d1d7e0;
		}
	}

	.create-book__input__remove-btn {
		margin: 0.55rem 0.75rem;
		cursor: pointer;
		color: #ccc;
		&:hover {
			color: #9f9f9f;
		}
	}
	.upload-front-cover-wrapper {
		display: flex;
		gap: 20px;
	}

	.DraftEditor-root {
		min-height: 200px;
		box-sizing: border-box;
		border: 0.0625rem solid #d1d7e0;
		cursor: text;
		padding: 16px;
		margin-bottom: 1em;
		background: #fefefe;
		border-radius: 0.5rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
}
.book-process {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.custom-editor {
	.public-DraftEditor-content {
		min-height: 160px;
	}
}
