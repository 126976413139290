.group {
	&__modal-info-group {
		&__column-right {
			max-height: 70vh;
		}
	}

	&__toggle-edit {
		width: fit-content;

		.form-check-input {
			background-color: white;
			&:checked {
				background-color: #0948b3;
			}
		}
	}

	&__modal {
		&__link:hover {
			cursor: pointer;
			color: blue;
			text-decoration: underline;
		}
	}
}
