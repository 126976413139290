.newfeed {
	.table td,
	.table th {
		white-space: inherit;
	}
	&__content {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
