.users-page {
	.table-responsive {
		min-height: 230px;
	}
	.selected-filter-thead {
		font-size: 13px;
		text-transform: none;
	}
}
.margin-negative {
	margin-top: -170px;
}
