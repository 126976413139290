.table-search {
	width: fit-content;
	.search-bar .form-control {
		width: 200px;
	}
}
.accordion-button {
	&:not(.collapsed) {
		box-shadow: none !important;
	}
}

.table-responsive {
	min-height: 230px;
}

.spinner {
	animation: loading-indicator 1.2s linear infinite;
}
