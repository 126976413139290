.book-copyrights-modal {
	&__documents {
		width: 110px;
		height: 180px;
		border-radius: 5px;
		position: relative;
		cursor: pointer;
		& img {
			height: 100%;
			border-radius: 5px;
		}
		&__plus {
			font-size: 32px;
			position: absolute;
			background-color: gray;
			color: white;
			font-weight: 1000;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			bottom: 50%;
			transform: translate(0%, 50%);
			opacity: 0.7;
		}
	}
}
