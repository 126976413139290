.edit-user {
	.img {
		object-fit: cover;
	}

	&__toggle-edit {
		width: fit-content;

		.form-check-input {
			background-color: white;
			&:checked {
				background-color: #0948b3;
			}
		}

		&__buttons--isDisabled {
			opacity: 0.65;
			pointer-events: none;
		}
	}

	&__upload-image {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		background-color: #f7f7fc;
		border: #e6e7f3 1px solid;
		cursor: pointer;

		p {
			margin-bottom: 0;
		}

		&.cover {
			height: 250px;
			width: 100%;
		}
	}

	&__user-cover {
		height: inherit;
		width: inherit;
	}

	.inp-socials-group {
		&__input:focus {
			z-index: inherit;
		}
		&__button {
			border-color: #d1d7e0;
			background-color: #e3e3e3;
			svg {
				transform: scale(1.2);
			}
		}
	}

	.inp-socials-readonly:hover {
		color: blue;
		text-decoration: underline;
		cursor: pointer;
	}

	.btn-change-avatar {
		width: 30px;
		height: 30px;
		display: flex;
		padding-top: 6px;
		justify-content: center;
		position: absolute;
		right: 100vw;
		bottom: 3%;
		background-color: #d1d7e0;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 0.5s;
		&.show {
			right: 12%;
		}
	}

	.btn-change-cover {
		padding: 5px 10px;
		display: flex;
		gap: 10px;
		position: absolute;
		justify-content: center;
		align-items: center;
		background-color: white;
		border-radius: 10px;
		box-shadow: 5px 5px 5px;
		top: -100vh;
		left: 10px;
		cursor: auto;
		opacity: 0;
		transition: opacity 0.5s;
		&.show {
			top: 10px;
		}
	}

	.social-links {
		opacity: 0;
		transition: opacity 0.5s;
		pointer-events: none;
		&.show {
			pointer-events: inherit;
		}
	}

	.show {
		opacity: 1;
		transition: opacity 0.5s;
	}

	.css-1s2u09g-control {
		padding-left: 4px;
		min-height: 43.6px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #66799e;
		border-radius: 0.5rem;
		border: 0.0625rem solid #d1d7e0;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&:hover {
			border-color: #d1d7e0;
		}
	}

	.css-1pahdxg-control {
		padding-left: 4px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #66799e;
		border-radius: 0.5rem;
		border-color: #566190 !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		@include box-shadow($input-box-shadow, $input-focus-box-shadow);
	}

	.css-14el2xx-placeholder,
	.css-6j8wv5-Input,
	.css-1pndypt-Input {
		color: #66799e;
		padding: 0.25rem 0;
	}

	.css-1insrsq-control {
		background-color: #f5f8fb;
		border: 0.0625rem solid #d1d7e0;
		min-height: 43.6px;
		border-radius: 0.5rem;
	}

	.rich-text-description {
		&.disabled {
			pointer-events: none;
			background-color: #f5f8fb;
		}
	}
}

.form-check-input:hover {
	cursor: pointer;
}
