.book-informations {
	.card {
		margin: auto;
		.card-body {
			display: flex;
			gap: 48px;
		}
	}

	.upload-front-cover-wrapper {
		width: 200px;
		min-width: 200px;
		height: 280px;
		img {
			width: 100%;
			height: 100%;
			border: $gray-400 1px solid;
		}
	}

	.book-informations__upload-image {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		font-size: 1rem;
		cursor: pointer;
	}

	.book-informations__info-form {
		flex-grow: 1;
	}

	.css-1s2u09g-control {
		padding-left: 4px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #66799e;
		border-radius: 0.5rem;
		border: 0.0625rem solid #d1d7e0;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&:hover {
			border-color: #d1d7e0;
		}
	}

	.css-319lph-ValueContainer {
		padding: 4px 0;
	}

	.css-1pahdxg-control {
		padding-left: 4px;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		border-radius: 0.5rem;
		border-color: #566190 !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		@include box-shadow($input-box-shadow, $input-focus-box-shadow);
	}

	.css-6j8wv5-Input {
		color: #66799e;
		padding: 0.25rem 0;
	}

	.css-1insrsq-control {
		height: 43.59px;
		border-radius: 0.5rem;
	}

	.book-informations__seri-input {
		box-shadow: none;
		&:read-only {
			background-color: white !important;
		}
		&:focus {
			border: 0.0625rem solid #d1d7e0;
		}
	}

	.book-informations__input__remove-btn {
		margin: 0.55rem 0.75rem;
		cursor: pointer;
		color: #ccc;
		&:hover {
			color: #9f9f9f;
		}
	}

	.create-new-author-name {
		color: $teal;
		text-decoration: underline;
		cursor: pointer;
		&:hover {
			color: $blue;
		}
	}
}

.rich-text-description {
	border: 0.0625rem solid #d1d7e0;
	border-radius: 0.5rem;
	padding: 0.55rem 0.75rem;
	word-break: break-word;
	position: relative;
	z-index: 0;
	.DraftEditor-root {
		height: fit-content;
	}
	> div {
		cursor: text;
	}

	.public-DraftEditor-content {
		> div {
			min-height: 150px;
		}
	}
}
