.create-user {
	.create-user__upload-image {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #f7f7fc;
		padding: 78px 12px;
		border-radius: 6px;
		font-size: 1rem;
		background-color: #f7f7fc;
		border: #e6e7f3 1px solid;
		cursor: pointer;
		width: 257px;
		height: 257px;
	}
	.card {
		max-width: 700px;
		margin: 0 auto;
	}
}
